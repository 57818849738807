import { Component, Input, OnInit, Output, signal } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { ArtworkDetailComponent } from 'src/app/artwork/artwork-detail.component';
import { ArtworkModel } from 'src/app/models/artwork.model';
import { Product } from 'src/app/models/product.model';
import { ArtworkService } from 'src/app/services/artwork.service';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { ProductService } from 'src/app/services/product.service';

export class ArtworkProducts {
  artworkId: number;
  artworkTitle: string;
  imageUrl: string;
  products: Product[];
}

export class ButtonState {
  artworkId: number;
  enabled: boolean;
}

@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  @Input() artworkId: number;
  productList: Product[] = [];

  displayedColumns: string[] = [
    'id',
    'description',
    'price',
    'width',
    'height',
    'action',
  ];
  dataSource: any; // = ELEMENT_DATA;

  dimensions: string[] = ['14" X 16" - $220', '20" X 30" - $300'];

  public myForm: FormGroup;
  artworkProducts: ArtworkProducts[] = [];

  // selectOptions = [{ productId: 1,  price: 100, dimension: '14"x17"'},
  //                   { productId: 2,  price: 100, dimension: '14"x17"'}]

  artworks: ArtworkModel[] = [];
  isReady: boolean;
  selectedProductId: number;
  // buttonsState: ButtonState[] = []
  buttonsStateHash = signal(new Object());

  maphash = new Map();


  constructor(
    public productService: ProductService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private artworkService: ArtworkService,
    private monitoringService: MonitoringService
  ) {}

  ngOnInit(): void {
    this.createFormBuilder();

    if (this.artworkId) {
      this.productService
        .get(this.artworkId)
        .subscribe((x) => (this.dataSource = x));
    } else {
      forkJoin([
        this.productService.getAll(),
        this.artworkService.getArtworks(),
      ]).subscribe(([x, y]) => {
        this.productList = x;
        this.artworks = y;
        // this.productService.getAll()
        // .subscribe((x) => {
        //   this.productList = x;
        this.groupProductByArtwork();
        this.addForms();
        this.isReady = true;
      });
    }
  }

  initializeButtons(): void {
    this.artworks.forEach((x) => {
      this.buttonsStateHash[x.artworkId] = false;
      //.push(0, { artworkId: x.artworkId, enabled: false });
    });
  }

  private groupProductByArtwork(): void {
    const artworkIds = _.uniq(_.map(this.productList, 'artworkId'));

    artworkIds.forEach((x) => {
      const products = this.productList.filter((y) => y.artworkId === x);
      const imageUrl = this.artworks.find((z) => z.artworkId === x)?.imageUrl;
      const artworkTitle = this.artworks.find((z) => z.artworkId === x)?.title;
      this.artworkProducts.push({
        artworkId: x,
        artworkTitle: artworkTitle,
        imageUrl: imageUrl,
        products: products,
      });
    });
  }

  private createFormBuilder(): void {
    this.myForm = this.fb.group({
      forms: this.fb.array([]),
    });
  }

  get forms(): FormArray {
    return this.myForm.get('forms') as FormArray;
  }

  private addForms(): void {
    this.artworkProducts.forEach((x) => {
      const form = this.fb.group({
        artworkId: new FormControl(x.artworkId),
        // productDimensions: new FormControl(x.products.map(y => `${y.width}"${y.height}" - ${y.price}` ))
        productDimensions: new FormControl('', Validators.required),
      });
      this.forms.push(form);
    });
  }

  getProductListByArtworkId(): void {
    // this.productList = ELEMENT_DATA;
  }

  onSelectChange(e: any): void {
    this.selectedProductId = e.target.value;
    const artworkId = this.productList.filter(x => x.id == this.selectedProductId)[0].artworkId;
    const unselectedArtworks = this.artworks.filter(x => x.artworkId != artworkId);

    const inActiveSelectControlIds = unselectedArtworks.map(y => y.artworkId + '-select');
    inActiveSelectControlIds.forEach(x => {
      const ctrl = document.getElementById(`${x}`);

      const selectCtrl = (ctrl as HTMLSelectElement);
      if (selectCtrl) {
        selectCtrl.options.selectedIndex = 0;
      }
    })

    const product = this.getProduct(this.selectedProductId);

    // Disable all keys first
    for (var key in this.buttonsStateHash) {
      if (this.buttonsStateHash.hasOwnProperty(key)) {
        this.buttonsStateHash[key] = false;
      }
    }

    this.buttonsStateHash[product.artworkId] = true;
  }

  getProduct(productId: number): Product {
    return this.productList.find((x) => x.id === +productId);
  }

  buy(artworkId:number) {
    console.log("logging buy button event");
    this.monitoringService.logEvent('buy_button_click', {artworkId: artworkId});
    alert("This print is currently not available. Please accept our apologies, and check back later!");
    return;
    const selectedProduct = this.getProduct(this.selectedProductId);

    if (!selectedProduct) {
      alert('Item not found!!!');
      return;
    }
    window.open(selectedProduct.paymentLink, '_blank');
  }

  displayImageDetail(artworkId: number): void {
    const artwork = this.artworks.find(x => x.artworkId === artworkId);
    // this.selectedImageUrl = this.artwork.imageUrl;
    // this.selectedArtworkTitle = this.artwork.title;

    const dialogRef = this.dialog.open(ArtworkDetailComponent, {
      data: { artwork: artwork }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
