import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Product } from "../models/product.model";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  get(artworkId: number): Observable<Product> {
    return of(
      { id: 2, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' })
  }

  getAll(): Observable<Product[]> {
    return of(
      this.getTestProducts()
    );
  }

  getTestProducts(): Product[] {
    return [
      { id: 1, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 2, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 3, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 4, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 5, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 6, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 7, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 10, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 11, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 12, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 13, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 14, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 16, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 17, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 18, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 19, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 20, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 21, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 22, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 23, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 24, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 25, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
    ];
  }

  getProducts(): Product[] {
    return [
      { id: 1, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 2, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 3, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 6, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 7, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 10, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 11, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 12, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 13, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 14, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 16, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 17, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 18, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 19, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 20, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 21, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 22, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 23, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 24, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 25, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
    ];
  }
}
