import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth-service';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { LocalStorageService } from './services/local-storage.service';
import { ngrxDataToastService } from './services/ngrx/ngrx-data-toast.service';
import { UserRoleService } from './services/role-services';
import { SessionService } from './services/session.service';
import { SocialMediaAuthService } from './services/social-media-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'kikisoso';
  isIframe = false;
  loading = true;
  year : number = new Date().getFullYear();
  subs: Subscription[] = [];
  loading$ = this.loadingIndicatorService.loading$;

  constructor(public authService: AuthService, private msalService: MsalService, private sessionService: SessionService, private ngrxDataToastService: ngrxDataToastService, public router: Router, private loadingIndicatorService: LoadingIndicatorService,
    private roleService: UserRoleService,
    private localStorageService: LocalStorageService,
    private socialMediaAuthService: SocialMediaAuthService
    ) {
    this.initLoadingIndicator()
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    // this.setUserLoginStatusChangeSubscription();

    // this.socialMediaLoginService.notifyLoginState();
    this.ngrxDataToastService.initializeSubscription();

    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount)
    {
      // this.sessionService.getUserData();
      this.authService.refreshLoginState();
    } else {
      // const providerName = localStorage.getItem(authenticationProvider);
      const providerName = this.localStorageService.getAuthSettings()?.providerName;
      if (this.socialMediaAuthService.socialMediaProviders?.some(x => x === providerName)) {// === 'Google' || providerName === 'Facebook') {
        this.socialMediaAuthService.refreshLoginState();
      } else {
        //alert("User is NOT authenticated. No provider setting found.");
      }
    }
  };

  // private setUserLoginStatusChangeSubscription() {
  //   this.subs.push(
  //     this.authService.isUserLoggedIn$.subscribe((x) => {
  //       if (x) {
  //         // Run initialization code that should happen right after login
  //       }
  //     })
  //   );
  // }

  public initLoadingIndicator(): void {
    this.router.events
          .subscribe(ev => {
            if (ev instanceof NavigationStart) {
              this.roleService.getUserRoleClaims();
              this.loadingIndicatorService.show();
            }

            if (ev instanceof NavigationEnd || ev instanceof NavigationCancel || ev instanceof NavigationError) {
              this.loadingIndicatorService.hide();
            }
          })
  }

  login(): void {
    this.authService.login();
  }

  logout(): void {
    this.authService.logout();
  }
}
