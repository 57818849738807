import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
// import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DefaultDataServiceConfig, EntityDataModule } from '@ngrx/data';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { entityConfig } from './entity-metadata';

import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AmazonLoginProvider,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule } from '@abacritt/angularx-social-login';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material/material.module';
// import { ToolbarModule } from './core/toolbar/toolbar.module';
import { NavbarComponent } from './core/navbar/navbar.component';
import { SidenavComponent } from './core/navbar/sidenav/sidenav.component';
import { LayoutComponent } from './core/layout/layout.component';
import { environment } from 'src/environments/environment';
import { RootStoreModule } from './root-store/root-store.module';
import { defaultEntityServiceConfig } from './services/ngrx/default-entityservice.config';
import { ContactComponent } from './contact/contact.component';
import { AdminComponent } from './admin/admin.component';
// import { MatIconModule } from '@angular/material/icon';
import { ArtworksComponent } from './artwork/artworks.component';
import { ArtworkDetailComponent } from './artwork/artwork-detail.component';
import { ImageViewerComponent } from './imageViewer/imageViewer.component';
import { ShopComponent } from './shop/artworks/shop.component';
import { SocialMediaAuthInterceptorService } from './services/social-media-auth-interceptor.service';
import { ProductListComponent } from './shop/artworks/product-list.component/product-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyBioComponent } from './my-bio/my-bio.component';
import { MyTeacherComponent } from './my-teacher/my-teacher.component';
import { AccoladesComponent } from './accolades/accolades.component';
import { ReturnPolicyComponent } from './shop/return-policy/return-policy.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    LoginComponent,
    NavbarComponent,
    SidenavComponent,
    LayoutComponent,
    ContactComponent,
    AdminComponent,
    ArtworkDetailComponent,
    ArtworksComponent,
    ImageViewerComponent,
    ShopComponent,
    ReturnPolicyComponent,
    // StripeFormComponent,
    ProductListComponent,
    MyBioComponent,
    MyTeacherComponent,
    AccoladesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RootStoreModule,
    // FlexLayoutModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule,

    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true}),


    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: environment.oAuthSettings.clientId, //'f6a88d97-f33d-4e39-911d-9b8fa9dcee35', // This is your client ID
        authority: environment.oAuthSettings.authority, //'https://login.microsoftonline.com/9253a53e-dae7-4db4-81b3-2aef2329d070', // This is your tenant ID
        redirectUri: environment.oAuthSettings.redirectUri //'http://localhost:4200/'// This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Popup,
      authRequest: {
        scopes: [...environment.oAuthSettings.scopes]
      }
    }, {
      interactionType: InteractionType.Popup,
      protectedResourceMap: new Map([
        // ['https://graph.microsoft.com/v1.0/me', ['user.read']]
        [`${environment.graphBaseUri}/me`, [...environment.oAuthSettings.scopes]]
      ])
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: SocialMediaAuthInterceptorService, multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, //keeps the user signed in
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider('966689992686-luqg9jsiehhnkel45qomk1cv6q7f86lq.apps.googleusercontent.com',
          //   {
          //       // scopes: ['profile', 'openid', 'email']
          //       scopes: 'profile openid' //[...environment.apiScopes, ...environment.oAuthSettings.scopes]
          //     }
          //   )
          // },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('3516980551866759') // your client id
          // },
          // {
          //   id: AmazonLoginProvider.PROVIDER_ID,
          //   provider: new AmazonLoginProvider('amzn1.application-oa2-client.3828f834fdb44bf08479c526702ae25d') // your client id
          // },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
      // }
    },
    { provide: DefaultDataServiceConfig, useValue: defaultEntityServiceConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
